import { Helmet } from "react-helmet";
import lyft from "../images/lyft.png";
import uber from "../images/uber.png";
import Button from "react-bootstrap/Button";
import map from "../images/map.jpg";
import main from "../images/Aloha Tower.jpg";

function Parking() {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="parking.css" />
        <title>Majestic by Atlantis Cruises | Ride Services & Parking</title>
      </Helmet>
      <img
        src={main}
        alt="Aloha Tower"
        className="w-100 object-fit-cover"
        style={{ height: "50vh", objectPosition: "bottom center" }}
      />
      <div className="container mw-100">
        <div className="row justify-content-center text-center whaleLevelsBG py-5 text-light">
          <h2 className="fw-bold mb-4">RIDE SERVICES & PARKING INFORMATION</h2>
          <p className="mb-0 fs-5">
            <strong>Majestic by Atlantis Cruises Departs</strong>
            <br />
            301 Aloha Tower Drive, Honolulu, Hawaii 96813
            <br />
            Located at Pier 6, Aloha Tower
          </p>
        </div>
        <div className="row py-5 text-center bg-light justify-content-center justify-content-sm-evenly justify-content-lg-center">
          <h3 className="whaleBlueH pb-5">RIDE SERVICES</h3>
          <div className="col-12 col-sm-6 col-md-5  col-xl-4 col-xxl-3 mb-5 mb-sm-0">
            <img
              style={{ display: "block" }}
              src={lyft}
              alt="lyft"
              className="fullH mb-5 mx-auto"
            />
            <Button
              variant="dark"
              href="https://lyft.com"
              style={{
                backgroundColor: "#415F84",
                borderRadius: "0",
                border: "none",
              }}
            >
              REQUEST A RIDE
            </Button>
          </div>
          <div className="col-12 col-sm-6 col-md-5 col-xl-4 col-xxl-3">
            <img
              style={{ display: "block" }}
              src={uber}
              alt="uber"
              className="fullH mb-5 mx-auto"
            />
            <Button
              variant="dark"
              href="https://uber.com"
              style={{
                backgroundColor: "#415F84",
                borderRadius: "0",
                border: "none",
              }}
            >
              REQUEST A RIDE
            </Button>
          </div>
        </div>
        <div
          className="row py-5 justify-content-center"
          style={{ backgroundColor: "#8C9BAE" }}
        >
          <div className="col-10 col-md-6 text-center">
            <h3 className="text-light fw-bold mb-4">HONOLULU TAXI SERVICES</h3>
            <p className="text-light fs-5">
              <strong>Charley's Taxi</strong> 808-233-3333
            </p>
            <p className="text-light fs-5">
              <strong>Honolulu Taxi</strong> 808-429-1388
            </p>
            <p className="text-light fs-5 mb-0">
              <strong>Yellow Cab Honolulu</strong> 808-699-9999
            </p>
          </div>
        </div>
        <div className="row bg-light justify-content-center">
          <div className="col-12 p-0">
            <img src={map} alt="parking map" className="w-100 p-0" />
          </div>
        </div>
        <div className="row py-5 justify-content-evenly justify-content-xxl-center alohaTowerBG text-light">
          <h3 className="text-light fw-bold mb-4 text-center">
            PARKING DETAILS
          </h3>
          <div className="col-11 col-md-4 col-lg-3 d-flex justify-content-start justify-content-md-center">
            <div>
              <p>
                <strong>
                  1. Pier 5 and 6 Parking Rates
                  <br />
                  at Aloha Tower
                </strong>
                <br />
                $3 per half hour, $30 max
              </p>
              <p className="mb-md-0">
                <strong>2. Harbor Square</strong>
                <br />
                700 Richards St. Honolulu, HI 96813
                <br />
                <strong>Weekday Evening</strong>
                <br />
                (Mon-Fri in after 4pm),
                <br />
                Flat rate $5
                <br />
                <strong>Weekend</strong>
                <br />
                (Sat-Sun),
                <br />
                All day flat rate $5
              </p>
            </div>
          </div>
          <div className="col-11 col-md-4 col-lg-3 d-flex justify-content-start justify-content-md-center">
            <div>
              <p>
                <strong>3. Pacific Guardian Center</strong>
                <br />
                739 Alakea St. Honolulu, HI 96813
                <br />
                <strong>Weekday Evening </strong>
                $4 after 4pm
                <br />
                <strong>Weekend </strong>
                $4 per half hour, $64 max
              </p>
              <p className="mb-md-0">
                <strong>4. Topa Financial Center</strong>
                <br />
                700 Bishop St. & 745 Fort St.
                <br />
                Honolulu, HI 96813
                <br />
                <strong>Evening </strong>
                After 4pm: $5
                <br />
                <strong>Weekend </strong>
                $5
                <br />
                6am-5pm: $3.25 per half hour,
                <br />
                $45 max
              </p>
            </div>
          </div>
          <div className="col-11 col-md-4 col-lg-3 d-flex justify-content-start justify-content-md-center">
            <div className="w-75">
              <p>
                <strong>5. Topa Financial Center</strong>
                <br />
                847 Bethel St. Honolulu, HI 96813
                <br />
                <strong>Evening </strong>
                Mon-Fri 5pm-6am
                <br />
                Sat-Sun - All day
                <br />
                $0.50 per half hour, $3 per 24 hours
              </p>
              <p className="mb-0 text-break">
                Parking facilities are not owned or operated by Majestic by
                Atlantis Cruises, and are subject to change without notice.
                Please inquire at the parking facilities for their current
                rates.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center py-5 bg-light text-center">
          <div className="col-12 col-md-9 col-lg-8 col-xl-6 p-0">
            <iframe
              title="googleMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3717.158981769787!2d-157.86689398462488!3d21.30473348367454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c006e0cf5b9b25f%3A0x3d4e4707e75af323!2sMajestic%20by%20Atlantis%20Cruises!5e0!3m2!1sen!2sus!4v1675966816981!5m2!1sen!2sus"
              style={{ border: 0 }}
              width="94%"
              height="400"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div
              className="col-12 text-start pt-3 px-0 mx-auto"
              style={{ width: "94%" }}
            >
              <form
                action="http://maps.google.com/maps"
                method="get"
                target="_blank"
              >
                <strong className="text-dark">Get Directions</strong>
                <br />
                <p className="text-dark">
                  Enter your starting address and click Go.
                </p>
                <input
                  type="text"
                  name="saddr"
                  className="rounded-0 border border-secondary-subtle"
                />
                <input
                  type="hidden"
                  name="daddr"
                  value="301 Aloha Tower Drive, Pier 6"
                />
                <input
                  type="submit"
                  className="directionsGo text-light"
                  value="Go"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Parking;
